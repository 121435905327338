import { axiosInstance } from 'boot/axios'

export const PageTemplate = {
  getById: (params = {}) =>
    axiosInstance.get(`v1/PageTemplate/TemplateID`, { params }),
}

// export const SignUpAgent = {
//   checkAvailability: (body) =>
//     axiosInstance.post(`v1/Signup/Availablity`, body),
//   signUp: (body) => axiosInstance.post(`v1/Signup`, body),
//   VerifyOtp: (body) => axiosInstance.post(`v1/Signup/VerifyOtp`, body),
//   ResendOtp: (body) => axiosInstance.post(`v1/Signup/ResendOtp`, body),
//   checkCustomerExits: () => axiosInstance.get(`v1/Signup/Exist`),
//   signUpAsCustomer: (body) => axiosInstance.post(`v1/Signup/Exist`, body),
// }
