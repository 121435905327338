<template>
  <q-page class="preview-template q-pt-none q-pb-xxl">
    <q-no-ssr>
      <div class="container main-container q-pt-md q-pb-sm">
        <div class="container-fluid" v-show="loading">
          <div class="row">
            <div class="col-12 relative-position">
              <q-skeleton type="rect" :height="isMobile ? '320px' : '560px'" />
              <div
                class="absolute-center z-9 bg-white r-12"
                :style="isMobile ? 'width: 240px' : 'width: 320px'"
              >
                <q-card flat class="q-width q-px-md q-py-lg r-12 s-1">
                  <q-card-section class="q-pa-none">
                    <q-skeleton
                      type="text"
                      width="80%"
                      height="30px"
                      class="text-caption q-mt-sm q-mx-auto"
                    />
                    <q-skeleton
                      type="text"
                      width="30%"
                      height="30px"
                      class="text-caption q-mx-auto"
                    />
                  </q-card-section>

                  <q-card-actions class="q-pt-md q-pl-none q-pr-none">
                    <q-skeleton width="160px" type="QBtn" class="q-mx-auto" />
                  </q-card-actions>
                </q-card>
              </div>
              <div class="absolute left-middle z-9">
                <q-skeleton
                  type="circle"
                  width="40px"
                  height="40px"
                  class="bg-white q-ma-md s-1"
                />
              </div>
              <div class="absolute right-middle z-9">
                <q-skeleton
                  type="circle"
                  width="40px"
                  height="40px"
                  class="bg-white q-ma-md s-1"
                />
              </div>
              <div class="absolute-bottom z-9">
                <div class="row q-col-gutter-md justify-center q-mb-md">
                  <q-skeleton
                    type="circle"
                    width="10px"
                    height="10px"
                    class="bg-white q-ma-sm s-1"
                  />
                  <q-skeleton
                    type="circle"
                    width="10px"
                    height="10px"
                    class="bg-white q-ma-sm s-1"
                  />
                  <q-skeleton
                    type="circle"
                    width="10px"
                    height="10px"
                    class="bg-white q-ma-sm s-1"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="q-product-slider q-product-slider-loader"
                v-for="n in 3"
                :key="n"
              >
                <div class="row q-col-gutter-md q-mb-sm">
                  <div class="col-12">
                    <q-skeleton
                      type="rect"
                      width="200px"
                      height="20px"
                      class="q-mb-md"
                    />
                  </div>
                  <div class="col" v-for="n in 7" :key="n">
                    <q-card flat bordered class="q-width q-pa-sm">
                      <q-skeleton width="100%" height="180px" class="q-mb-md" />
                      <q-card-section
                        class="row no-wrap q-pt-sm q-pl-none q-pr-none q-pb-none"
                      >
                        <div class="col">
                          <q-skeleton width="60%" height="15px" />
                        </div>
                        <div class="col-1">
                          <q-skeleton width="15px" height="15px" />
                        </div>
                      </q-card-section>
                      <q-card-section
                        class="q-pt-sm q-pl-none q-pr-none q-pb-none"
                      >
                        <q-skeleton
                          type="text"
                          width="100%"
                          class="text-caption q-mt-sm"
                        />
                        <q-skeleton
                          type="text"
                          width="30%"
                          class="text-caption"
                        />
                      </q-card-section>

                      <q-card-section
                        class="q-pt-none q-pl-none q-pr-none q-pb-none"
                      >
                        <q-skeleton width="40%" height="15px" class="q-mt-md" />
                      </q-card-section>

                      <q-card-actions
                        class="q-pt-md q-pl-none q-pr-none q-pb-none"
                      >
                        <q-skeleton width="100%" type="QBtn" />
                      </q-card-actions>
                    </q-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SplitSection
          v-for="(section, key, index) in visibleSections"
          :key="`${key}-${index}`"
          :section="section"
          isPreview
        />

        <EmptyComponent
          v-if="!loading && Object.keys(visibleSections).length == 0"
          image="images/empty-product.png"
        >
          <p>Oops! Nothing here.</p>
        </EmptyComponent>
      </div>
    </q-no-ssr>
  </q-page>
</template>

<script>
// import ImageSlider from 'components/home-page-components/ImageSlider'
// import Collection from 'components/home-page-components/CollectionPreview'
// import SingleImage from 'components/home-page-components/SingleImage.vue'

import { PageTemplate } from 'src/agent'
import { mapGetters } from 'vuex'

export default {
  name: 'PreviewTemplate',
  // components: {
  // ImageSlider,
  // Collection,
  // SingleImage
  // },

  data() {
    return {
      templateData: null,
      loading: true,
    }
  },
  mounted() {
    this.loading = true

    if (this.$route?.query?.templateId) {
      PageTemplate.getById({
        templateID: this.$route.query.templateId,
        filterAgeRestrictedItem: this.storeHasAgeRestriction,
      })
        .then((response) => {
          this.templateData = response?.data?.data || null
        })
        .catch(() => {
          this.templateData = null
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.templateData = null
      this.loading = false
    }
  },
  computed: {
    ...mapGetters('ageVerification', ['showAgeVerificationOption']),
    visibleSections() {
      let layerJson = this.templateData?.layerJson
      let visibleSections = {}

      for (const unId in layerJson) {
        if (layerJson[unId].visible) {
          visibleSections[unId] = layerJson[unId]
        }
      }

      return visibleSections
    },
  },
}
</script>
<style lang="scss"></style>
